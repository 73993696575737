@tailwind base;
@tailwind components;
@tailwind utilities;

main {
  min-height: 90vh;
  max-width: 1280px;
  margin: auto;
  padding: 15px;
}


.dropdown:hover > .dropdown-content {
	display: block;
}
